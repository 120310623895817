// src/pages/user-experience-design-ux.js

import React from "react"
import data from "../data/yaml/userexpierence_page.yaml"
import HeadMetadata from "../components/headMetadata"
import HeaderBar from "../components/headerBar"
import Banner from "../components/banner"
import Service  from "../components/service"
import Work from "../components/work"
import ContactSection from "../components/contact"
import Footer from "../components/footer"

export default () => {
  return (
    <>
      <HeadMetadata {...data.metadata} />
      <HeaderBar />
      <Banner {...data} />
      <Service {...data} />
      <Work pageId="experience" {...data.image} />
      <ContactSection headline={data.contact.headline}  />
      <Footer />
    </>
  )
}
